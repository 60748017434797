/* eslint-disable */

import {
  PaymentIntentConfig,
  Address,
  PaymentFieldDetailDto,
  MessageDetailDto,
  AccountConfig,
  AccountFieldGetDto,
  PaymentSourceConfig,
  CreditCardConfig,
  TokenizerConfig,
  AcceptedCardBrands,
  AchConfig,
  PaymentSourceSummaryDto,
  OptionalAddress,
  ProductConfig,
  ProductSummaryDto,
  PaymentIntentDetailDto,
  PaymentSessionLineItemGetDto,
  Currency,
  AccountSummaryDto,
  ServiceFeeCalculationDto,
  ServiceFeeTierGetDto,
  CreatePaymentIntentRequest,
  Charges,
  OpenCharge,
  AccountCharge,
  ProductCharge,
  PaymentIntentPaymentSource,
  NewPaymentSource,
  CreateIvrPaymentIntentRequest,
  UpdatePaymentIntentPaymentFieldsRequest,
  UpdatePaymentIntentChargesRequest,
  UpdatePaymentIntentFormRequest,
  UpdateIvrPaymentIntentChargesRequest,
  UpdatePaymentIntentPaymentSourceRequest,
  CreatePaymentSessionSourceFromPlaidRequest,
  UpdatePaymentIntentCardDataRequest,
  EmptyResponse,
  Error,
  UpdateIvrPaymentIntentPaymentSourceRequest,
  ProcessPaymentIntentRequest,
  PaymentSessionDetailDto,
  ServiceFeeCalculation,
  PaymentDetailDto,
  TransactionDetailDto,
  PaymentSummaryDto,
  PaymentSourceJsonDto,
  PaymentIntentResultDto,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class PaymentIntentsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getConfig(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      channel: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentConfig>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/channel/{channel}/payment-intent-config';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{channel}', params['channel'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      identifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      body?: CreatePaymentIntentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static createIvr(
    params: {
      /**  */
      body?: CreateIvrPaymentIntentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/ivr';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentFields(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdatePaymentIntentPaymentFieldsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/payment-fields';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCharges(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdatePaymentIntentChargesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/charges';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateCheckoutForms(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdatePaymentIntentFormRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/forms';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateIvrCharges(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdateIvrPaymentIntentChargesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/charges/ivr';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentSource(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdatePaymentIntentPaymentSourceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/payment-source';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentSourceFromPlaid(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: CreatePaymentSessionSourceFromPlaidRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/payment-source/plaid';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updatePaymentSourceCardData(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdatePaymentIntentCardDataRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/payment-source/card-data';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateIvrPaymentSource(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: UpdateIvrPaymentIntentPaymentSourceRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/payment-source/ivr';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static process(
    params: {
      /**  */
      identifier: string;
      /**  */
      body?: ProcessPaymentIntentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/process';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static abandon(
    params: {
      /**  */
      identifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentSessionDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/abandon';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getResult(
    params: {
      /**  */
      identifier: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<PaymentIntentResultDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/result';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getFeeAmount(
    params: {
      /**  */
      identifier: string;
      /**  */
      paymentSourceType?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<ServiceFeeCalculationDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/payment-intents/{identifier}/fee-amount';
      url = url.replace('{identifier}', params['identifier'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { paymentSourceType: params['paymentSourceType'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
