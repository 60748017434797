export const colors = {
  white: '#FFFFFF',
  black: '#000000',
  blue50: '#D8E6FD',
  blue100: '#B9D2FB',
  blue200: '#99BEF9',
  blue300: '#7AA9F7',
  blue400: '#5A95F5',
  blue500: '#2A70EE',
  blue600: '#2F67C2',
  blue700: '#234D92',
  blue800: '#183461',
  gray50: '#F1F1F6',
  gray100: '#D8D9E0',
  gray200: '#BCBEC7',
  gray300: '#A1A4AF',
  gray400: '#858996',
  gray500: '#696E7E',
  gray600: '#545865',
  gray700: '#3F424C',
  gray800: '#2A2C32',
  gray900: '#151619',
  green50: '#D4F8EB',
  green100: '#AAF1D7',
  green200: '#80EAC3',
  green300: '#55E3B0',
  green600: '#00AA6D',
  green700: '#008052',
  green800: '#005536',
  green900: '#002B1B',
  red50: '#FBE1E1',
  red100: '#F8C2C2',
  red200: '#F4A4A4',
  red300: '#F08686',
  red500: '#E94949',
  red600: '#BA3A3A',
  red700: '#8C2C2C',
  red800: '#5D1D1D',
  red900: '#2F0F0F',
  teal50: '#E5F9F9',
  teal100: '#B6E4E6',
  teal200: '#91D7D9',
  teal300: '#6CCACC',
  teal500: '#23afb3',
  teal600: '#1C8C8F',
  teal800: '#0E4648',
  teal900: '#072324',
  yellow50: '#fff7eb',
  yellow300: '#ffcd80',
  yellow600: '#CC8E2D',
  yellow900: '#33230B',

  inputValue: '#3F424C',
  muted: '#696E7E',
  text: '#2A2C32',
  errorBorder: '#d8726a',
  errorText: '#a8473d',
  primaryBlue: '#3B81F3',
} as const;

export const fontSizes = {
  xs: '.8em',
  sm: '.925em',
  md: '1em',
  lg: '1.075em',
  xl: '1.2em',
} as const;

export type SpacingToken = keyof typeof spacings;
export const spacings = {
  xs: '0.625rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.25rem',
  xl: '2rem',
} as const;
