import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useAsyncRetry} from 'react-use';
import {Container, Header, Loader, Message} from 'semantic-ui-react';
import {css} from '@emotion/react/macro';
import {Link, RouteComponentProps, useLocation, useNavigate, useParams} from '@reach/router';
import {
  AccountDetailDto,
  DependentFormResponseDto,
  Error,
  PaymentIntentDetailDto,
  Response,
  SubmitFormResponse,
} from '../api/generated/index.defs';
import {FormsService} from '../api/generated/FormsService';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';
import {payStarColors} from '../styles/styled';
import {lighten} from 'polished';
import {Media} from '../styles/breakpoints';
import {User} from '../types';
import {routes} from '../routes';
import {PaymentIntentsService} from '../api/generated/PaymentIntentsService';
import {sessionStore} from '../utils/session-store';
import moment from 'moment';
import {DateTimeFormats} from '../components/date';
import {
  buildIsOutsideRangeForDatePicker,
  customFormFields,
  mapContextToFields,
} from './custom-form-fields';
import {FormAction, formActionHandlers} from './form-actions/form-actions';

const DEFAULT_ON_FORM_COMPLETE_URL = routes.customer.dashboard;

enum FormEvents {
  OnInitializing = 'OnInitializing',
  OnInitialized = 'OnInitialized',
  OnSubmitting = 'OnSubmitting',
}

export type CustomFormProps = RouteComponentProps & {
  account?: AccountDetailDto;
  businessUnitId: number;
  user?: User;
  formId?: number;
  paymentSessionSessionIdentifier?: string;
  onCompleteUrl?: string;
  onComplete?: () => void;
  onCancel?: () => void;
};

const getNextFormStage = (
  currentStage: 'Initializing' | 'Initialized' | 'Submitting'
): 'Initializing' | 'Initialized' | 'Submitting' => {
  switch (currentStage) {
    case 'Initializing':
      return 'Initialized';
    case 'Initialized':
      return 'Submitting';
    default:
      return 'Submitting';
  }
};

export const CustomForm: React.FC<CustomFormProps> = ({
  account,
  businessUnitId,
  formId,
  user,
  onCompleteUrl,
  onComplete,
  onCancel,
  paymentSessionSessionIdentifier,
}) => {
  const navigate = useNavigate();
  const {baseFormId} = useParams<any>();
  const location = useLocation();

  const isDependentForm = !!baseFormId;

  const [submitResults, setSubmitResults] = useState<{
    info?: boolean;
    error?: boolean;
    content?: string;
  }>({});

  const [formActionState, setFormActionState] = useState<{
    formStage: 'Initializing' | 'Initialized' | 'Submitting';
    resolvingAction: boolean;
    currentAction?: FormAction;
    availableActions: FormAction[];
    data: any;
  }>({formStage: 'Initializing', resolvingAction: false, availableActions: [], data: {}});

  const [dependentFormState, setDependentFormState] = useState<DependentFormResponseDto[]>([]);

  const fetchFormConfiguration = useAsyncRetry(async () => {
    if (!formId) return null;

    var request = !user
      ? FormsService.getAnonymousFormConfigurationById
      : FormsService.getFormConfigurationById;

    const {data} = await request({
      businessUnitId: businessUnitId,
      formConfigurationId: formId,
    });
    return data;
  });

  useEffect(() => {
    setDependentFormState(
      JSON.parse(sessionStore.getAndRemove(`formConfiguration:${formId}`) ?? '[]')
    );
  }, [formId]);

  const contextData = useMemo(() => {
    const parsedMeta = JSON.parse(account?.meta ?? '{}');
    const accountMeta = Object.keys(parsedMeta).reduce(
      (obj, curr) => ({...obj, [`meta_${curr}`]: parsedMeta[curr]}),
      {}
    );

    const data = {
      ...accountMeta,
      ...account,
      ...user,
      ...formActionState?.data,
      date: moment().format(DateTimeFormats.DateTime),
    };

    return data;
  }, [account, user, formActionState?.data]);

  const getFormFields = useMemo(() => {
    if (!fetchFormConfiguration.value) return [];
    const schema: any = JSON.parse(fetchFormConfiguration?.value?.fieldsJson ?? '{}');

    return (
      schema.fields?.reduce((acc: JSX.Element[], curr) => {
        const {fieldType, fieldMatches, ...propsToApply} = curr;
        return [...acc, customFormFields[fieldType](propsToApply) ?? null];
      }, []) ?? []
    );
  }, [fetchFormConfiguration.value]);

  const getActionHandler = useMemo(() => {
    if (!formActionState.currentAction) return null;

    const defaultHandlerProperties = {
      ...(formActionState?.currentAction?.handler?.properties ?? {}),
      businessUnitId,
      formConfigurationId: formId,
      originalFields: formActionState?.data?.originalFields,
      fieldSchema: JSON.parse(fetchFormConfiguration?.value?.fieldsJson ?? '{}'),
    };

    var handler =
      formActionHandlers[formActionState?.currentAction?.handler?.handlerType?.toUpperCase() ?? ''];

    return handler
      ? handler(
          defaultHandlerProperties,
          contextData,
          (data) => {
            setFormActionState({
              formStage: getNextFormStage(formActionState.formStage),
              availableActions: formActionState.availableActions,
              data: {
                ...data,
                originalFields: defaultHandlerProperties.originalFields,
                updatedFields: data,
              },
              currentAction: undefined,
              resolvingAction: false,
            });
          },
          () =>
            setFormActionState({
              ...formActionState,
              formStage: getNextFormStage(formActionState.formStage),
              currentAction: undefined,
              resolvingAction: false,
            }),
          (errorMessage) => setSubmitResults({error: true, content: errorMessage})
        )
      : null;
  }, [
    businessUnitId,
    contextData,
    fetchFormConfiguration?.value?.fieldsJson,
    formActionState,
    formId,
  ]);

  const navigateToDependentForm = useCallback(
    (dependentFormConfigurationId, values) => {
      sessionStore.set(`formConfigurationState:${formId}`, JSON.stringify(values));
      navigate(`${location.pathname}/${dependentFormConfigurationId}${location.search}`);
    },
    [location.pathname, location.search, navigate, formId]
  );

  const getDependentForms = (values) => {
    if (!fetchFormConfiguration.value) return [];
    const schema: any = JSON.parse(fetchFormConfiguration?.value?.fieldsJson ?? '{}');

    if (!schema.dependentForms || schema.dependentForms?.length === 0) return [];

    let remainingDependentForms = false;

    let dependentForms =
      schema.dependentForms?.reduce((acc: JSX.Element[], curr) => {
        const {fieldType, fieldMatches, ...propsToApply} = curr;
        const {
          fieldName,
          content: formName,
          dependentFormConfigurationId,
          ...remainingPropsToApply
        } = propsToApply;
        const matchingState =
          !!dependentFormConfigurationId &&
          dependentFormState?.some(
            (x) => `${x?.formConfigurationId}` === dependentFormConfigurationId
          );

        remainingDependentForms = remainingDependentForms || !matchingState;

        return [
          ...acc,
          matchingState ? (
            <Message key={fieldName}>Completed additional form: {formName}</Message>
          ) : (
            <Form.Button
              key={fieldName}
              type="button"
              disabled={!!matchingState}
              onClick={() => navigateToDependentForm(dependentFormConfigurationId, values)}
              content={formName}
              {...remainingPropsToApply}
            />
          ),
        ];
      }, []) ?? [];

    if (remainingDependentForms) {
      dependentForms = [
        <Header
          className="field"
          key="dependentFormsHeader"
          content="Please complete the following additional forms:"
        />,
        ...dependentForms,
      ];
    }

    return <div className="dependent-forms-container">{dependentForms}</div>;
  };

  const getSubmitAction = async (values) => {
    if (!formId || !fetchFormConfiguration?.value) return null;

    const valuesToSubmit = mapContextToFields(
      fetchFormConfiguration?.value?.fieldsJson,
      'hiddenFields',
      contextData,
      values
    );
    const validationResult = validateForm(
      fetchFormConfiguration.value.fieldsJson,
      valuesToSubmit,
      dependentFormState
    );
    if (validationResult.hasErrors) return validationResult;

    const onSubmitFormAction =
      formActionState.formStage === 'Initialized'
        ? formActionState?.availableActions[FormEvents.OnSubmitting]
        : null;

    if (!!onSubmitFormAction) {
      setFormActionState({
        ...formActionState,
        data: {
          ...formActionState.data,
          originalFields: valuesToSubmit,
        },
        resolvingAction: true,
        currentAction: onSubmitFormAction,
      });
      return;
    }

    return await onSubmit(values);
  };

  const onSubmit = async (values) => {
    if (!formId || !fetchFormConfiguration?.value) return null;

    const valuesToSubmit = mapContextToFields(
      fetchFormConfiguration?.value?.fieldsJson,
      'hiddenFields',
      contextData,
      values
    );

    // TODO: probably don't need to validate again here (onSubmit is only called from getSubmitAction)
    const validationResult = validateForm(
      fetchFormConfiguration.value.fieldsJson,
      valuesToSubmit,
      dependentFormState
    );
    if (validationResult.hasErrors) return validationResult;

    let response: Response<PaymentIntentDetailDto> | Response<SubmitFormResponse>;

    if (paymentSessionSessionIdentifier) {
      response = await PaymentIntentsService.updateCheckoutForms({
        identifier: paymentSessionSessionIdentifier,
        body: {
          businessUnitId: businessUnitId,
          accountId: account?.id ?? 0,
          customerId: user?.id ?? 0,
          valuesJson: JSON.stringify(valuesToSubmit),
          paymentSessionSessionIdentifier: `${paymentSessionSessionIdentifier}`,
          formConfigurationId: formId,
          baseFormResponseId: 0,
        },
      });
    } else {
      const request = !user ? FormsService.submitAnonymousForm : FormsService.submitForm;

      response = await request({
        businessUnitId: businessUnitId,
        formConfigurationId: formId,
        body: {
          accountId: account?.id ?? 0,
          customerId: user?.id ?? 0,
          valuesJson: JSON.stringify(valuesToSubmit),
          baseFormResponseId: isDependentForm ? baseFormId : undefined,
          paymentSessionSessionIdentifier: '',
          isDependentForm,
          dependentFormResponses: dependentFormState,
        },
      });
    }

    const submitResults = {
      info: !response.hasErrors,
      error: response.hasErrors,
      content: response.hasErrors
        ? 'An error occurred submitting the form'
        : 'Form successfully submitted',
    };

    if (response.hasErrors) {
      notifications?.error(submitResults.content);
      return;
    }

    notifications?.success(submitResults.content);

    if (!!onComplete) {
      onComplete();
      return;
    } else if (!!onCompleteUrl) {
      navigate(onCompleteUrl);
      return;
    } else if (isDependentForm) {
      const baseFormUrl = location.pathname.split('/').slice(0, -1).join('/');
      const returnUrl = `${baseFormUrl}${location.search}`;

      const existingData = JSON.parse(sessionStore.get(`formConfiguration:${baseFormId}`) ?? '[]');
      sessionStore.set(
        `formConfiguration:${baseFormId}`,
        JSON.stringify([...existingData, response.data])
      );

      navigate(returnUrl);
      return;
    }

    setSubmitResults(submitResults);
  };

  const configureActions = (actionsJson: string | undefined) => {
    const actions = JSON.parse(actionsJson ?? '{}');

    const startingStage =
      formActionState.formStage === 'Initializing' &&
      Object.hasOwn(actions, FormEvents.OnInitializing)
        ? 'Initializing'
        : 'Initialized';

    const actionForCurrentStage =
      startingStage === 'Initializing'
        ? actions[FormEvents.OnInitializing]
        : startingStage === 'Initialized'
        ? actions[FormEvents.OnInitialized]
        : startingStage === 'Submitting'
        ? actions[FormEvents.OnSubmitting]
        : undefined;

    setFormActionState({
      ...formActionState,
      formStage: startingStage,
      availableActions: Object.keys(actions).length > 0 ? actions : [],
      currentAction: actionForCurrentStage,
      resolvingAction: !!actionForCurrentStage,
    });
  };

  const getInitialValues = useMemo(() => {
    if (!fetchFormConfiguration?.value) return {};

    // Consider timestamping or utilizing UUID
    const sessionFormData = JSON.parse(
      sessionStore.getAndRemove(`formConfigurationState:${formId}`) ?? '{}'
    );

    const mappedValues = mapContextToFields(
      fetchFormConfiguration?.value?.fieldsJson,
      'fields',
      contextData
    );

    const originalFields = formActionState.data?.originalFields ?? {};
    const updatedFields = formActionState.data?.updatedFields ?? {};

    const initialValues = {
      ...mappedValues,
      ...originalFields,
      ...updatedFields,
      ...sessionFormData,
    };

    return initialValues;
  }, [
    fetchFormConfiguration?.value,
    formId,
    contextData,
    formActionState.data?.originalFields,
    formActionState.data?.updatedFields,
  ]);

  useEffect(() => {
    configureActions(fetchFormConfiguration?.value?.actionsJson);
  }, [fetchFormConfiguration?.value?.actionsJson]);

  return (
    <Container css={styles}>
      <Header>{fetchFormConfiguration?.value?.displayName}</Header>
      <Form.Container>
        {submitResults?.content ? (
          <>
            <Message {...submitResults} />
            <div className="form-actions">
              {onComplete ? (
                <Form.Button type="button" onClick={onComplete}>
                  Back
                </Form.Button>
              ) : (
                <Form.Button as={Link} to={onCompleteUrl ?? DEFAULT_ON_FORM_COMPLETE_URL}>
                  Back
                </Form.Button>
              )}
            </div>
          </>
        ) : !!fetchFormConfiguration?.value ? (
          formActionState.resolvingAction ? (
            <>{getActionHandler}</>
          ) : (
            <Form
              initialValues={getInitialValues}
              onSubmit={getSubmitAction}
              render={({values}) => (
                <>
                  {getFormFields}
                  {getDependentForms(values)}

                  <Form.ErrorNotice />
                  <div className="form-actions">
                    <Form.Button type="submit" primary>
                      {isDependentForm ? 'Continue' : 'Submit'}
                    </Form.Button>
                    {onCancel ? (
                      <Form.Button type="button" onClick={onCancel}>
                        Cancel
                      </Form.Button>
                    ) : (
                      <Form.Button
                        as={Link}
                        primary
                        to={onCompleteUrl ?? DEFAULT_ON_FORM_COMPLETE_URL}
                      >
                        Cancel
                      </Form.Button>
                    )}
                  </div>
                </>
              )}
            />
          )
        ) : !fetchFormConfiguration?.value ? (
          <Loader active content="Loading" />
        ) : null}
      </Form.Container>
    </Container>
  );
};

const styles = css`
  .ui.button.primary[type='submit'],
  .ui.button.primary[type='button'] {
    background-color: ${payStarColors.primary.blue};
    color: white;

    &:hover,
    &:focus {
      background-color: ${lighten(0.1, payStarColors.primary.blue)} !important;
    }
  }

  ${Media('MobileMax')} {
    .form-actions {
      margin-top: 1rem;
    }
  }

  input:read-only {
    color: grey;
  }

  .ui.radio.checkbox {
    display: flex;
  }

  .ui.checkbox input.hidden + label {
    margin-bottom: 8px;
  }

  .ui.form .ui.header {
    margin-bottom: 0.3rem;
  }

  .dependent-forms-container {
    margin-bottom: 1.25rem;
  }
`;

const failsFieldRequired = (schemaRecord, submittedValues): Error | undefined => {
  const isValid = !schemaRecord.fieldRequired || !!submittedValues[schemaRecord.fieldName];

  return isValid
    ? undefined
    : {
        errorMessage: `${schemaRecord.fieldLabel || 'This'} is a required field.`,
        propertyName: schemaRecord.fieldName,
      };
};

const failsFieldMatches = (schemaRecord, submittedValues, schema): Error | undefined => {
  const isValid =
    !schemaRecord.fieldMatches ||
    `${submittedValues[schemaRecord.fieldName]}`.toLocaleLowerCase() ===
      `${submittedValues[`${schemaRecord.fieldMatches}`]}`.toLocaleLowerCase();

  if (isValid) return undefined;

  const fieldLabelToMatch =
    schema.fields.filter((x) => x.fieldName === schemaRecord.fieldMatches)[0]?.fieldLabel ??
    'Field';

  return isValid
    ? undefined
    : {
        errorMessage: `This field must match ${fieldLabelToMatch}`,
        propertyName: schemaRecord.fieldName,
      };
};

const failsDateLimits = (schemaRecord, submittedValues): Error | undefined => {
  const skipsValidation =
    schemaRecord.fieldType !== 'datepicker' ||
    (schemaRecord.minimumDayOffset === undefined &&
      schemaRecord.maximumDayOffset === undefined &&
      schemaRecord.skipWeekends === undefined);

  if (skipsValidation) return undefined;

  const selectedDateIsOutsideRange = buildIsOutsideRangeForDatePicker(
    schemaRecord.minimumDayOffset,
    schemaRecord.maximumDayOffset,
    schemaRecord.skipWeekends
  );

  const isInvalid = selectedDateIsOutsideRange(moment(submittedValues[schemaRecord.fieldName]));

  return isInvalid
    ? {
        errorMessage: 'The selected date is not available',
        propertyName: schemaRecord.fieldName,
      }
    : undefined;
};

const validateForm = (
  fieldsJson: string,
  valuesToSubmit: any,
  submittedDependentForms: DependentFormResponseDto[]
): Response<unknown> => {
  const parsedFields = JSON.parse(fieldsJson);
  let errors = parsedFields.fields.reduce((errors, curr) => {
    const requiredError = failsFieldRequired(curr, valuesToSubmit);
    if (!!requiredError) return [...errors, requiredError];

    const matchError = failsFieldMatches(curr, valuesToSubmit, parsedFields);
    if (!!matchError) return [...errors, matchError];

    const dateRangeError = failsDateLimits(curr, valuesToSubmit);
    if (!!dateRangeError) return [...errors, dateRangeError];

    return errors;
  }, []);

  if (parsedFields.dependentForms?.length > 0) {
    const allFormsCompleted =
      parsedFields.dependentForms?.length === submittedDependentForms.length &&
      parsedFields.dependentForms.every((x) =>
        submittedDependentForms.some(
          (y) => `${y.formConfigurationId}` === `${x.dependentFormConfigurationId}`
        )
      );

    if (!allFormsCompleted)
      errors = [
        ...errors,
        {
          errorMessage: `Complete additional forms before submitting this form`,
          propertyName: 'dependentForms',
        },
      ];
  }

  const response: Response<unknown> = {
    data: null,
    hasErrors: errors.length > 0,
    errors,
  };

  return response;
};
