/* eslint-disable */

import {
  AdvancedQueryColumnFilter,
  AdvancedQueryColumnSort,
  AccountSummaryDto,
  OptionalAddress,
  CreateAccountRequest,
  AccountGetDto,
  AccountDetailDto,
  CustomerAccountGetDto,
  AutopayEnrollmentSettings,
  ScheduledPaymentCustomerSummaryDto,
  AccountMergeSearchDto,
  UpdateAccountRequest,
  EmptyResponse,
  Error,
  MergeAccountsRequest,
  AccountDetailCustomerDto,
  UpdateAccountNotesRequest,
  BlobUploadUrlResult,
  ProcessAccountUploadRequest,
  ViewJobSummaryDto,
  AccountAutopaySettingsDto,
  AccountAutopayUpdateEnrollmentRequest,
  AutopayEnrollmentResponse,
  BillSummaryDto,
  IList,
  List,
  Response,
  AdvancedQueryPage,
  IRequestOptions,
  IRequestConfig,
  getConfigs,
  axios,
  basePath
} from './index.defs';

export class AccountsService {
  /** Generate by swagger-axios-codegen */
  // @ts-nocheck
  /* eslint-disable */

  /**
   *
   */
  static getAll(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      isArchived?: boolean;
      /**  */
      isPos?: boolean;
      /**  */
      onlyAutopayEnabled?: boolean;
      /**  */
      onlyRegisteredCustomers?: boolean;
      /**  */
      onlyRestrictedAccounts?: boolean;
      /**  */
      onlyPaperlessBilling?: boolean;
      /**  */
      notifyUpcomingPayment?: boolean;
      /**  */
      notifyNewBill?: boolean;
      /**  */
      notifyWithSms?: boolean;
      /**  */
      isPastDue?: boolean;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsArchived: params['isArchived'],
        IsPos: params['isPos'],
        OnlyAutopayEnabled: params['onlyAutopayEnabled'],
        OnlyRegisteredCustomers: params['onlyRegisteredCustomers'],
        OnlyRestrictedAccounts: params['onlyRestrictedAccounts'],
        OnlyPaperlessBilling: params['onlyPaperlessBilling'],
        NotifyUpcomingPayment: params['notifyUpcomingPayment'],
        NotifyNewBill: params['notifyNewBill'],
        NotifyWithSms: params['notifyWithSms'],
        IsPastDue: params['isPastDue'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static create(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      body?: CreateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getById(
    params: {
      /**  */
      id: number;
      /**  */
      businessUnitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/{businessUnitId}';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static initiatePosAccountSearch(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      accountNumber?: string;
      /**  */
      subaccountNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/initiate-pos-account-search';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accountNumber: params['accountNumber'], subaccountNumber: params['subaccountNumber'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static search(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      query?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<AccountSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/search';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { query: params['query'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static mergeSearch(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      destinationAccountId?: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<AccountMergeSearchDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/merge-search';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        DestinationAccountId: params['destinationAccountId'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateAccountRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountGetDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static deleteById(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static merge(
    params: {
      /**  */
      body?: MergeAccountsRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/merge';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getContext(options: IRequestOptions = {}): Promise<Response<AccountDetailDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/context';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getMyAccounts(options: IRequestOptions = {}): Promise<Response<List<AccountDetailCustomerDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/mine';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static download(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      isArchived?: boolean;
      /**  */
      isPos?: boolean;
      /**  */
      onlyAutopayEnabled?: boolean;
      /**  */
      onlyRegisteredCustomers?: boolean;
      /**  */
      onlyRestrictedAccounts?: boolean;
      /**  */
      onlyPaperlessBilling?: boolean;
      /**  */
      notifyUpcomingPayment?: boolean;
      /**  */
      notifyNewBill?: boolean;
      /**  */
      notifyWithSms?: boolean;
      /**  */
      isPastDue?: boolean;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/download';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        IsArchived: params['isArchived'],
        IsPos: params['isPos'],
        OnlyAutopayEnabled: params['onlyAutopayEnabled'],
        OnlyRegisteredCustomers: params['onlyRegisteredCustomers'],
        OnlyRestrictedAccounts: params['onlyRestrictedAccounts'],
        OnlyPaperlessBilling: params['onlyPaperlessBilling'],
        NotifyUpcomingPayment: params['notifyUpcomingPayment'],
        NotifyNewBill: params['notifyNewBill'],
        NotifyWithSms: params['notifyWithSms'],
        IsPastDue: params['isPastDue'],
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateNotes(
    params: {
      /**  */
      id: number;
      /**  */
      body?: UpdateAccountNotesRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<string>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/update-notes/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('patch', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static archive(
    params: {
      /**  */
      id: number;
      /**  */
      shouldRollover?: boolean;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/archive/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { shouldRollover: params['shouldRollover'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static unarchive(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/unarchive/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getUploadUrl(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      fileName?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<BlobUploadUrlResult>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/get-upload-url';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { fileName: params['fileName'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static processUpload(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      body?: ProcessAccountUploadRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/process-upload';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccountUploadConfig(
    params: {
      /**  */
      businessUnitId: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<ViewJobSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/account-upload-config';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sendPaymentDueReminders(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/send-payment-due-reminders';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAutopaySettings(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AccountAutopaySettingsDto>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/autopay-settings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateAutopaySettings(
    params: {
      /**  */
      id: number;
      /**  */
      body?: AccountAutopayUpdateEnrollmentRequest;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AutopayEnrollmentResponse>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/autopay-settings';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params['body'];

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static cancelAutopay(
    params: {
      /**  */
      id: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<EmptyResponse> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{id}/autopay-unenroll';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAccounts(
    params: {
      /**  */
      slug: string;
      /**  */
      accountNumber?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<List<AccountDetailDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/accounts/{slug}/get-accounts';
      url = url.replace('{slug}', params['slug'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { accountNumber: params['accountNumber'] };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static getAllAccountMessages(
    params: {
      /**  */
      businessUnitId: number;
      /**  */
      accountId: number;
      /**  */
      page?: number;
      /**  */
      pageSize?: number;
      /**  */
      searchFieldNames?: string[];
      /**  */
      searchSearchText?: string;
      /**  */
      searchExactMatch?: boolean;
      /**  */
      filtered?: AdvancedQueryColumnFilter[];
      /**  */
      sorted?: AdvancedQueryColumnSort[];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<Response<AdvancedQueryPage<BillSummaryDto>>> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/api/business-units/{businessUnitId}/accounts/{accountId}/messages';
      url = url.replace('{businessUnitId}', params['businessUnitId'] + '');
      url = url.replace('{accountId}', params['accountId'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        Page: params['page'],
        PageSize: params['pageSize'],
        'Search.FieldNames': params['searchFieldNames'],
        'Search.SearchText': params['searchSearchText'],
        'Search.ExactMatch': params['searchExactMatch'],
        Filtered: params['filtered'],
        Sorted: params['sorted']
      };
      let data = null as any;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}
