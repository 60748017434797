import React, {useEffect} from 'react';
import {Container, Header, Image} from 'semantic-ui-react';
import {css} from '@emotion/react';
import {RouteComponentProps} from '@reach/router';
import {Media} from '../styles/breakpoints';
import {CustomForm} from './custom-form';
import {RouteParams, buildPath, routes} from '../routes';
import {BusinessUnitsService} from '../api/generated/BusinessUnitsService';
import {useAsync} from 'react-use';
import {payStarColors} from '../styles/styled';
import {Env} from '../config/env-vars';

export const AnonymousForms = (props: RouteComponentProps) => {
  const {businessUnitId, baseFormId, formId, slug} = props as RouteParams<{
    formId: number;
    baseFormId?: number;
    businessUnitId: number;
    slug: string;
  }>;

  // FIXME: lazy-load this component w/ imported semantic
  const [stylesLoaded, setStylesLoaded] = React.useState(false);
  useEffect(() => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `${Env.publicUrl}/semantic-compiled.css`;
    link.type = 'text/css';

    link.onload = () => setStylesLoaded(true);
    link.onerror = () => setStylesLoaded(true);

    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const fetchHeader = useAsync(async () => {
    const {data} = await BusinessUnitsService.getHeaderById({id: businessUnitId});
    return data;
  }, [businessUnitId]);

  if (!stylesLoaded) {
    return null;
  }

  return (
    <div css={styles}>
      <div className="header-container">
        <div className="header__business-unit-logo">
          {fetchHeader?.value?.logoUrl && (
            <Image src={fetchHeader?.value?.logoUrl} centered className="logo" alt="Company Logo" />
          )}
        </div>
        <div className="header__business-unit-headers">
          <Header>
            {fetchHeader?.value?.header}
            <Header.Subheader>{fetchHeader?.value?.subHeader}</Header.Subheader>
          </Header>
        </div>
      </div>
      <Container text className="form-container">
        <CustomForm
          businessUnitId={businessUnitId}
          formId={formId}
          onCompleteUrl={!baseFormId ? buildPath(routes.customer.dashboard, {slug}) : undefined}
        />
      </Container>
    </div>
  );
};

const styles = css`
  .form-container {
    margin-top: 95px;
  }
  .header-container {
    position: fixed;
    height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
    border-top: solid 5px ${payStarColors.blue6};
    border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    box-shadow: 0px 0px 2px 0 rgba(34, 36, 38, 0.15);
    background: #fff;
    position: fixed;
    z-index: 1;
    width: 100%;
    top: 0;
  }
  .header {
    &__business-unit-logo img {
      height: 50px;
    }
    &__business-unit-headers {
      padding-left: 0.75rem;
      .ui.header {
        font-weight: normal;
      }
    }
  }
  .brand-image {
    height: 50px !important;
    width: auto !important;
    margin-top: 0px !important;
  }
  .ui.button.icon-right svg {
    margin-left: 0.5rem;
  }
  .ui.button.icon-left svg {
    margin-right: 0.5rem;
  }

  ${Media('MobileMax')} {
    .header__business-unit-logo {
      display: none;
    }
    .header__business-unit-headers .ui.header {
      font-size: 1.25rem;
      .sub.header {
        font-size: 1rem;
      }
    }
  }
`;
