import React, {useMemo, useState} from 'react';
import {css} from '@emotion/react/macro';
import {useAsync} from 'react-use';
import {Button, Header, Loader, Modal} from 'semantic-ui-react';
import {FormsService} from '../../api/generated/FormsService';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {Media} from '../../styles/breakpoints';
import {Form} from '../../forms';
import {customFormFields} from '../custom-form-fields';
import {faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {colors} from '../../v2/styles/tokens';

export type FormPickerProps = {
  loadingMessage: string;
  keepUpdatedActionText: string;
  originalFields: Record<string, string>;
  originalHeader: string;
  keepOriginalActionText: string;
  updateHeader: string;
  updatedHeader: string;
  businessUnitId: number;
  formConfigurationId: number;
  functionTriggerId: number;
  requestBody: any;
  fieldSchema: any;
  onSuccess: (any) => void;
  onCancel: (any) => void;
  onError: (any) => void;
};

export const FormPicker: React.FC<FormPickerProps> = ({
  loadingMessage,
  businessUnitId,
  formConfigurationId,
  functionTriggerId,
  requestBody,
  originalFields,
  originalHeader,
  keepUpdatedActionText,
  updatedHeader,
  updateHeader,
  keepOriginalActionText,
  fieldSchema,
  onSuccess,
  onCancel,
  onError,
}) => {
  const [open, setOpen] = useState<boolean>(true);
  const [updatedFields, setUpdatedFields] = useState<Record<string, string>>({});

  const submitRequest = useAsync(async () => {
    if (!businessUnitId || !formConfigurationId || !functionTriggerId) return null;
    const actionRequestBody = {
      functionTriggerId,
      valuesJson: JSON.stringify(requestBody ?? {}),
    };
    const result = await FormsService.callAction({
      businessUnitId,
      formConfigurationId,
      body: actionRequestBody,
    });

    if (result.hasErrors) {
      onError(result.errors.map((x) => x.errorMessage).join('\n'));
      return;
    }

    if (result.data.result) {
      setUpdatedFields((result.data.result as any)?.updatedFields ?? {});
      return;
    }

    return result;
  });

  const fieldNames = useMemo(() => Object.keys(updatedFields).map((f) => f), [updatedFields]);

  return (
    <div css={styles}>
      {submitRequest?.loading ? (
        <Loader active content={loadingMessage ?? 'Loading'} />
      ) : (
        <Modal
          open={open}
          size="large"
          onClose={() => {
            setOpen(false);
            onCancel(null); // TODO: idk about this - this will cause the next 'submit' to actually submit but they didn't actually confirm
          }}
          closeIcon={
            <span className="close-icon">
              <Form.Button type="button" circular icon color="black">
                <FontAwesomeIcon icon={faTimes} />
              </Form.Button>
            </span>
          }
        >
          <Modal.Header>{updateHeader}</Modal.Header>
          <Modal.Content>
            <div css={formPickerStyles}>
              <div>
                <Header>{originalHeader}</Header>
                <Form
                  initialValues={originalFields}
                  onSubmit={() => onCancel(null)}
                  render={() => (
                    <>
                      {fieldSchema.fields
                        .filter((fieldSchema) => fieldNames.includes(fieldSchema.fieldName))
                        .map((fieldSchema) => {
                          const {fieldType, fieldMatches, ...propsToApply} = fieldSchema;
                          return customFormFields[fieldSchema.fieldType]({
                            ...propsToApply,
                            fieldRequired: false,
                            readOnly: true,
                            inputProps: {
                              ...propsToApply.inputProps,
                              readOnly: true,
                            },
                          });
                        })}
                      <Button
                        secondary
                        type="submit"
                        fluid
                        content={keepOriginalActionText ?? 'Cancel'}
                      />
                    </>
                  )}
                />
              </div>
              <div className="icon-container">
                <FontAwesomeIcon icon={faArrowRight} className="icon" />
              </div>
              <div>
                <Header>{updatedHeader}</Header>
                <Form
                  initialValues={updatedFields}
                  onSubmit={() => onSuccess(updatedFields)}
                  render={() => (
                    <>
                      {fieldSchema.fields
                        .filter((fieldSchema) => fieldNames.includes(fieldSchema.fieldName))
                        .map((fieldSchema) => {
                          const {fieldType, fieldMatches, ...propsToApply} = fieldSchema;
                          return customFormFields[fieldSchema.fieldType]({
                            ...propsToApply,
                            fieldRequired: false,
                            readOnly: true,
                            inputProps: {
                              ...propsToApply.inputProps,
                              readOnly: true,
                            },
                          });
                        })}
                      <Button
                        primary
                        type="submit"
                        fluid
                        content={keepUpdatedActionText ?? 'Cancel'}
                      />
                    </>
                  )}
                />
              </div>
            </div>
          </Modal.Content>
        </Modal>
      )}
    </div>
  );
};

const formPickerStyles = css`
  display: grid;
  gap: 3.2rem 4.8rem;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;

  ${Media('MobileMax')} {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto 1fr;

    .icon {
      rotate: 90deg;
    }
  }

  .ui.radio.checkbox {
    display: block;
    margin: 0.25rem 0;
  }

  .ui.button {
    margin-top: 2.2rem;
  }

  .icon-container {
    justify-self: center;
  }
  .icon {
    color: ${colors.gray300};
  }
`;

const styles = css`
  min-height: 25px;
`;
