import React from 'react';
import {FormLoader, FormLoaderProps} from './form-loader';
import {FormPicker, FormPickerProps} from './form-picker';

type FormActionHandler = {
  handlerType: string;
  showWhen?: string;
  properties: any;
  contextValues: any;
};

export type FormAction = {
  executeOn?: string;
  handler?: FormActionHandler;
  onComplete?: () => void;
  onCancel?: () => void;
};

const mapDataToRequestBody = (requestBody, contextData) =>
  Object.keys(requestBody ?? {}).reduce(
    (acc, curr) => ({...acc, [curr]: contextData[curr] ?? requestBody[curr]}),
    {}
  );

const LOAD_FORM_VALUES = 'loadFormValues'.toUpperCase();
const SUGGEST_FORM_UPDATES = 'suggestFormUpdates'.toUpperCase();

export const formActionHandlers = {
  [LOAD_FORM_VALUES]: (
    propsToApply: FormLoaderProps,
    data,
    onSuccess: (any) => void,
    _: (any) => void,
    onError: (any) => void
  ) => (
    <FormLoader
      {...propsToApply}
      requestBody={mapDataToRequestBody(propsToApply.requestBody, data)}
      onSuccess={onSuccess}
      onError={onError}
    />
  ),
  [SUGGEST_FORM_UPDATES]: (
    propsToApply: FormPickerProps,
    data,
    onSuccess: (any) => void,
    onCancel: (any) => void,
    onError: (any) => void
  ) => {
    return (
      <FormPicker
        {...propsToApply}
        requestBody={mapDataToRequestBody(propsToApply.requestBody, data.originalFields)}
        onSuccess={onSuccess}
        onCancel={onCancel}
        onError={onError}
      />
    );
  },
};
