import React from 'react';
import {Header, Message} from 'semantic-ui-react';
import {Form} from '../forms';
import moment from 'moment';
import {isInclusivelyAfterDay, isInclusivelyBeforeDay} from 'react-dates';

export const mapContextToFields = (schemaJson, key, context, initialObject = {}) => {
  const schema = JSON.parse(schemaJson ?? '{}');
  const fields = schema[key] ?? [];

  const result = {
    ...initialObject,
    ...fields.reduce(
      (data, curr) => ({
        ...data,
        [`${curr.fieldName}`]: context[curr.mapFromContextKey] ?? curr.defaultValue,
      }),
      {}
    ),
  };

  return result;
};

const weekends = [0, 6];

const filterFormConfigurationProps = (providedProps) => {
  const {mapFromContextKey, ...propsToApply} = providedProps;
  return propsToApply;
};

export const buildIsOutsideRangeForDatePicker = (
  minimumDayOffset,
  maximumDayOffset,
  skipWeekends
) => {
  if (minimumDayOffset !== undefined) minimumDayOffset--;

  if (maximumDayOffset !== undefined) maximumDayOffset++;

  if (skipWeekends && moment().day() - minimumDayOffset <= 0) {
    minimumDayOffset -= 2;
  }

  if (skipWeekends && moment().day() + maximumDayOffset >= 6) {
    maximumDayOffset += 2;
  }

  const minimumDayCheck =
    minimumDayOffset !== undefined
      ? (x) => isInclusivelyBeforeDay(x, moment().add(minimumDayOffset, 'days'))
      : () => false;

  const maximumDayCheck =
    maximumDayOffset !== undefined
      ? (x) => isInclusivelyAfterDay(x, moment().add(maximumDayOffset, 'days'))
      : () => false;

  var weekendCheck = skipWeekends ? (x) => weekends.some((y) => x.day() === y) : () => false;

  return (x) => minimumDayCheck(x) || maximumDayCheck(x) || weekendCheck(x);
};

export const customFormFields = {
  input: (propsToApply) => (
    <Form.Input key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  'input-currency': (propsToApply) => (
    <Form.InputCurrency
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  'input-masked': (propsToApply) => (
    <Form.InputMasked
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  'input-decimal': (propsToApply) => (
    <Form.InputDecimal
      key={propsToApply.fieldName}
      {...filterFormConfigurationProps(propsToApply)}
    />
  ),
  datepicker: (propsToApply) => {
    const {minimumDayOffset, maximumDayOffset, skipWeekends, ...remainingPropsToApply} =
      propsToApply;

    return (
      <Form.DatePicker
        key={propsToApply.fieldName}
        isOutsideRange={buildIsOutsideRangeForDatePicker(
          minimumDayOffset,
          maximumDayOffset,
          skipWeekends
        )}
        {...filterFormConfigurationProps(remainingPropsToApply)}
      />
    );
  },
  dropdown: (propsToApply) => (
    <Form.Dropdown key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  radio: (propsToApply) => (
    <Form.RadioGroup key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  textarea: (propsToApply) => (
    <Form.TextArea key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  checkbox: (propsToApply) => (
    <Form.Checkbox key={propsToApply.fieldName} {...filterFormConfigurationProps(propsToApply)} />
  ),
  header: (propsToApply) => {
    const {fieldName, mapFromContextKey, ...remainingPropsToApply} = propsToApply;
    return <Header key={fieldName} {...filterFormConfigurationProps(remainingPropsToApply)} />;
  },
  message: (propsToApply) => {
    const {fieldName, mapFromContextKey, ...remainingPropsToApply} = propsToApply;
    return <Message key={fieldName} {...filterFormConfigurationProps(remainingPropsToApply)} />;
  },
};
