import React from 'react';
import {css} from '@emotion/react/macro';
import {useAsync} from 'react-use';
import {FormsService} from '../../api/generated/FormsService';
import {Loader} from 'semantic-ui-react';

export type FormLoaderProps = {
  loadingMessage: string;
  businessUnitId: number;
  formConfigurationId: number;
  functionTriggerId: number;
  requestBody: any;
  onSuccess: (any) => void;
  onError: (any) => void;
};

export const FormLoader: React.FC<FormLoaderProps> = ({
  loadingMessage,
  businessUnitId,
  formConfigurationId,
  functionTriggerId,
  requestBody,
  onSuccess,
  onError,
}) => {
  const submitRequest = useAsync(async () => {
    if (!businessUnitId || !formConfigurationId || !functionTriggerId) return onSuccess({});

    const actionRequestBody = {
      functionTriggerId,
      valuesJson: JSON.stringify(requestBody ?? {}),
    };
    const result = await FormsService.callAction({
      businessUnitId,
      formConfigurationId,
      body: actionRequestBody,
    });

    if (result.hasErrors) {
      return onError(result.errors.map((x) => x.errorMessage).join('\n'));
    }

    if (result.data) {
      onSuccess(result.data.result);
      return;
    }

    return result;
  });

  return (
    <div css={styles}>
      {submitRequest?.loading ? <Loader active content={loadingMessage ?? 'Loading'} /> : null}
    </div>
  );
};

const styles = css`
  min-height: 25px;
`;
